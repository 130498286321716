import React, { useEffect, useState } from 'react'
import { gsap } from "gsap";

import { Navbar, Footer } from './lab201' 

import {setCursorMode} from './utils'
import './styles/about.css'

export function About ({lang='en'}) {
  var mouseY = 0
  var lastScrollY = 0

    function onMouseMove(e) {
      const $cursor = document.querySelector('.cursor')
      lastScrollY = document.querySelector('html').scrollTop
      var x = e.pageX;
      var y = mouseY = e.pageY;
      $cursor.style.left = x + 'px';
      $cursor.style.top = y + 'px';
    }

  function onScroll(e) {
    console.log(document.querySelector('.cursor'))
    const $cursor = document.querySelector('.cursor')
    const scrollY = document.querySelector('html').scrollTop
    const offset = lastScrollY - scrollY
    $cursor.style.top = (mouseY - offset) + 'px'
  }

  useEffect( ()=> {
      document.body.addEventListener('mousemove', onMouseMove);
      window.addEventListener('scroll', onScroll);

      setCursorMode('.about__email', 'contact-hover')
  }, [])

  return (
    <div className="about__container">
      <Navbar active={'about'} lang={lang}/>

      <div className="about__content">

        <div className="about__section--1">
          <div className="about__section-header">
            <div className="about-h1">
              {"Impossible? We're on it."}
            </div>
          </div>
          <div className="about-p1 about__section-subheader">
            {(lang === 'kr') 
              ? ('Lab 201은 지속적인 문제 제기를 통해 문제를 해결하며 미래를 만들어가는 팀입니다. ' + 
                 '팀원 모두가 함께 디자인, 설계, 구축, 검증하는 역할을 수행합니다. 저희가 생각하는 ' +
                 '위대한 경험은 좋은 제품과 그 이야기 안에 있다고 믿기에, 혁신적이고 더 인간적인 경험을 ' +
                 '쌓는 일을 하고자 합니다.\n\n' + 
                 'Lab 201은 처음부터 끝까지 전 개발 단계에 디자인 창의성을 더하여 기억에 남는 디지털 ' +
                 '경험을 제공합니다.')
              : ('We are a multidisciplinary team of trouble-makers and problem-solvers working together ' +
                 'to design, engineer, build, and test the future.\n\n' +
                 'We believe that great experiences lie at the core of every great product and story, ' +
                 'and at Lab 201, we’re in the business of building elegant, more human experiences.  ' +
                 'We combine the creative capacity of a full service design house with an end-to-end ' +
                 'software engineering pipeline to deliver unforgettable, one-of-a-kind digital experiences.')}
          </div>
        </div>

        <div className="about__section about__section--2">
          <div className="about-h2 about__value">
            Design, for humans
          </div>
          <div className="about-p2 about__value-desc">
            {(lang === 'kr') 
              ? ('좋은 디자인은 사용자와 현실과의 깊은 연관성에 의해 주도된다고 믿기에, Lab 201은 ' +
                 '세상과 그 안에 있는 사람들에게 지속적인 영향을 남기는 인간 중심적인 경험을 제공하는 ' +
                 '데 전념하고 있습니다.')
              : ('We believe that great design is driven by a deep connection with users and the real ' +
                 'world, and we are committed to delivering elegant, human-centric experiences that leave ' +
                 'a lasting impact on the world and those in it.')}
          </div>
        </div>

        <div className="about__section about__section--3">
          <div className="about-h2 about__value">
            Innovation first
          </div>
          <div className="about-p2 about__value-desc">
            {(lang === 'kr') 
              ? ('Lab 201은 새로운 기술과 창의적인 접근 방식을 통해 기존에 불가능해보였던 ' + 
                 '한계에 자부심을 가지고 끊임없이 도전합니다.')
              : ('We pride ourselves on consistently pushing the boundaries of what’s possible by ' +
                 'challenging conventional practices with new technologies and fiercely creative approaches ' +
                 'to problem-solving and story-telling.')}
          </div>
        </div>

        <div className="about__section about__section--4">
          <div className="about-h2 about__value">
            Integrated Pipeline
          </div>
          <div className="about-p2 about__value-desc">
            {(lang === 'kr') 
              ? ('Lab 201의 디자인과 엔지니어링 파이프라인이 통합된 기술을 활용하여, 최초 설계부터 최종 ' +
                 '구현단계까지, 그리고 그 사이의 어디서든 고객의 현재 단계를 진단하여 만족감을 전달합니다.') 
              : ('We take you from first design to final implementation and everywhere in between, ' +
                 'with a fully integrated design and engineering pipeline that meets you wherever you’re at.')}
          </div>
        </div>

        <div className="about__section-email">
          <div className="about__section-email--col-1">
            <div className="about-h1 about__section-email-header">
              {"Let's talk!"}
            </div>
            <div className="about-p2">{"Time zones ain't no thing."}</div>
          </div>

          <div className="about__section-email--col-2">
            <a className="about__email" href="mailto:hello@lab201.com">
              hello@lab201.com
            </a>
            <img className="about__scribble" src="/static/media/scribble.c7fb91c34fcb7d1350c9cb38bb4a1977.svg" />
          </div>
        </div>
      </div>

      <Footer active='about' lang={lang} />
    </div>
  )
}
