import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GhostContentAPI from '@tryghost/content-api'

import logo from './assets/logo.svg';

import { Navbar, Footer } from './lab201'
import {setCursorMode} from './utils'
import './styles/posts.css'
//import './styles/blog-m.css';
//import './styles/blog.css';

const api = new GhostContentAPI({
    url: 'https://blog.lab201.com',
    key: '43e5445edb2c9d9e4f8c8ce161',
    version: "v5.0"
})

export function Post () {
  var mouseY = 0
  var lastScrollY = 0

  const { slug } = useParams()
  const [ post, setPost ] = useState(null)

  const onMouseMove = (e) => {
    const $cursor = document.querySelector('.cursor')
    lastScrollY = document.querySelector('html').scrollTop
    var x = e.pageX;
    var y = mouseY = e.pageY;
    $cursor.style.left = x + 'px';
    $cursor.style.top = y + 'px';
  }

  const onScroll = (e) => {
    const $cursor = document.querySelector('.cursor')
    const scrollY = document.querySelector('html').scrollTop
    const offset = lastScrollY - scrollY
    $cursor.style.top = (mouseY - offset) + 'px'
  }

  useEffect(() => {
    document.body.addEventListener('mousemove', onMouseMove);
    window.addEventListener('scroll', onScroll);
    api.posts.read({slug: slug}, {include: 'tags,authors'})
    .then((post) => {
      setPost(post)
      setCursorMode('.post__body figure', 'cursor--noblend')
    })
  }, [])


  return (
    <div className="post__container"> 
    <Navbar active={'work'} hasLogo={true} dark={false}/>

      <div className="post">
        <div className="post__info">
          {post?.tags && 
            post.tags.map((tag) => {
              return (
                <div className="post__tag" key={tag.id}>{tag.name}</div>
              )
            })
          }
          <div className="post__date">
            {post?.published_at && 
                new Date(post.published_at).toLocaleString('en-US', { month: 'short', year: 'numeric'})}
          </div>
        </div>

        <div className="post__title">{post?.title}</div>
        <div className="post__body" dangerouslySetInnerHTML={{__html: post?.html}}></div>

      </div>

      <Footer />
    </div>
  )
}
