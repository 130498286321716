import { useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import 'jquery.easing'

import logo from './assets/logo.svg';
import scribble from './assets/scribble.svg'
import { Navbar, Footer } from './lab201'

import './styles/base.css'
import './styles/home-m.css';
import './styles/home.css';

const _animateText = function (aTime, sTime, wordIndex, hasRun) {
  const wordOffset = '50px'
  const wordlist = ['스토리', '디자인', '엔지니어링', '브랜딩', '경험',
                    '디지털', '그래픽', '전략', '포지셔닝', '아이덴티티', 
                    '제품', '개발', '모든 것']

  let textElement = $('#active-word-kr')
  let currentWord = wordlist[wordIndex]

  textElement.animate({'top': ('-'+wordOffset), 'opacity': 0}, {easing: 'linear', duration: aTime, complete: () => {
    textElement.text(currentWord)
    let easing = 'easeInOutCirc'
    easing = 'linear'
    if (wordIndex === (wordlist.length - 1)) {
      textElement.css('font-weight', '900')
      textElement.css({'top': wordOffset}).animate({'top': '0', 'opacity': '1'}, {easing: easing, duration: aTime})
      .animate({top: '-5px', duration: 40, easing: 'linear'})

      textElement.css('filter', 'none')
      textElement.animate({top: '0px'}, {duration: 250, easing: 'easeInExpo', complete: () => {
        $('.underline-kr').animate({width: '29vw'}, {duration: 450, easing: 'easeInOutExpo'})
      }})
        
      wordIndex = 0;
      return
    } else {
      textElement.css({'top': wordOffset}).animate({'top': '0px', 'opacity': '1'}, {easing: easing, duration: aTime})
    }

    setTimeout(() => {
      wordIndex++
      if (wordIndex === wordlist.length) { return }
      else { _animateText(aTime + 0, sTime, wordIndex) }
    }, sTime)
  }});
}

function HomeKR({page}) {
  let canScroll = true
  let currentSection = 0;
  let sections = []
  let sectionContainer = []
  let touchStart = 0;
  let touchEnd = 0;
  let hasRun = false

  const performScroll = function (scrollDir) {
    currentSection += scrollDir;

    // Make sure we stick to real sections and don't scroll out of it.
    if (currentSection >= sections.length - 1) {
      currentSection = sections.length - 1;
    }
    if (0 > currentSection) {
      currentSection = 0;
    }

    // perform the 'scroll'
    if (currentSection === 3) {
      console.log('footer')
      sectionContainer.style.top = 'calc(-200vh - 250px)'
    } else {
    sectionContainer.style.top = (currentSection * -100) + 'vh';
    if (currentSection === 0) {
      sections[0].style.background = '#121212'
      sections[1].style.background = '#121212'
      sections[2].style.background = '#121212'
      $('.tile-badge').css('background-color', '#121212')
      $('.tile-badge').css('color', '#121212')
      $('#and-more').css('color', 'black')
      $('.tile-group--1 .about-tile').css('left', '50px')
      $('.tile-group--2 .about-tile').css('right', '50px')
    } else if (currentSection === 1) {
      sections[0].style.background = 'white'
      sections[1].style.background = 'white'
      sections[2].style.background = 'white' 
      $('.tile-badge').css('background-color', '#E20000')
      $('.tile-badge').css('color', '#FCFCFC')
      $('#and-more').css('color', 'white')
      if (!hasRun) _animateText(35, 0, 0)
      hasRun = true

      $('.tile-group--1 .about-tile').css('left', '0px')
      $('.tile-group--2 .about-tile').css('right', '0px')
    } else {
      sections[0].style.background = '#121212'
      sections[1].style.background = '#121212'
      sections[2].style.background = '#121212' 
      $('.tile-badge').css('background-color', '#121212')
      $('.tile-badge').css('color', '#121212')
      $('#and-more').css('color', 'black')
      $('.tile-group--1 .about-tile').css('left', '50px')
      $('.tile-group--2 .about-tile').css('right', '50px')
      setTimeout(() => {$('#scribble').addClass('stroke')}, 1000) 
    }
    }
  }


  useEffect(()=>{
    $('.contact-email').mouseenter(() => $('.cursor').addClass('contact-hover'))
    $('.contact-email').mouseleave(() => $('.cursor').removeClass('contact-hover'))

    $('.about-tile').mouseenter(() => $('.cursor').addClass('lang-hover'))
    $('.about-tile-container').mouseenter(() => $('.cursor').addClass('cursor--noblend'))
    $('.about-tile').mouseleave(() => $('.cursor').removeClass('lang-hover'))
    $('.about-tile-container').mouseleave(() => $('.cursor').removeClass('cursor--noblend'))

    $('.lang__option').not('.lang--active').mouseenter(() => $('.cursor').addClass('lang-hover'))
    $('.lang__option').not('.lang--active').mouseleave(() => $('.cursor').removeClass('lang-hover'))

    sections = document.querySelectorAll('.page, .footer');
    sectionContainer = document.querySelector('.viewport');

    if (page) {
    currentSection = 2
    sectionContainer.style.transition = 'unset'
      sections[0].style.background = '#121212'
      sections[1].style.background = '#121212'
      sections[2].style.background = '#121212'
      $('.tile-badge').css('background-color', '#121212')
      $('.tile-badge').css('color', '#121212')
      $('#and-more').css('color', 'black')
      $('.tile-group--1 .about-tile').css('left', '50px')
      $('.tile-group--2 .about-tile').css('right', '50px')
      setTimeout(() => {$('#scribble').addClass('stroke')}, 1000)

    sectionContainer.style.top = (currentSection * -100) + 'vh';
    setTimeout(() => {
    sectionContainer.style.transition = 'top 1s ease'
    }, 1000)
    }

    document.body.onmousemove = function(e) {
      document.documentElement.style.setProperty('--x', (e.clientX+window.scrollX) + 'px');
      if (currentSection === 3) {
      document.documentElement.style.setProperty('--y',
        (e.clientY+window.scrollY+(window.innerHeight * 2 + 250)) + 'px');
      } else {
        document.documentElement.style.setProperty('--y',
          (e.clientY+window.scrollY+(window.innerHeight * currentSection)) + 'px');
      }
    }


    document.addEventListener('touchstart', (event) => {
      touchStart = event.changedTouches[0].clientY;
    });

    document.addEventListener('touchend', (event) => {
      touchEnd = event.changedTouches[0].clientY;

      if (!canScroll) {
        touchStart = 0
        touchEnd = 0
         return;
      }


      canScroll = false;
        setTimeout(() => {
        canScroll = true;
      }, 1200);


      if (touchStart > touchEnd) {
        performScroll(1);
      } else {
        performScroll(-1);
      }
    });

    document.addEventListener('wheel', (event) => {

      event.preventDefault();
      event.stopPropagation();

      if (!canScroll) {
         return;
      }

      canScroll = false;
        setTimeout(() => {
        canScroll = true;
      }, 1200);

      let scrollDir = event.deltaY > 0 ? 1 : -1;
      performScroll(scrollDir)
    }, { passive: false })

  }, [])

  return (
    <div className="viewport">

      <HeroKR />
      <CapabilitiesKR />
      <ContactKR />
      <Footer active='' lang={'kr'} />
    </div>
  )
}

function HeroKR () {
  return (
    <div id="page-1" className="page" data-section-name="top">
      <Navbar lang="kr" />

      <div className="hello">
        <div className="hero">
          <span className="hero-text hero-kr">
            인간의 경험
          </span>
          <span className="hero-extra">을</span>
          <div className="hero-text-name hero-text-name-kr">
            디자인하다.
          </div>
        </div>
        <div className="hero-subheader">
          <span className="entry">랩 투오원 [læb tuː-oʊ-wʌn]: </span>
          <span className="definition">
            우리는 사람들에게 도움이 되는 것을 만들고, 전해야 할 이야기를 하고자 합니다.
  명쾌한 해법은 좋은 질문에서 나오고, 열정과 지식과 창의성이 충돌할 때 진정한 혁신이 탄생한다고 믿습니다.
          </span>
        </div>
      </div>
    </div>
  )
}

function CapabilitiesKR () {
  const [productDesignCount, setProductDesignCount] = useState(undefined)
  const [brandingCount, setBrandingCount] = useState(undefined)

  const [uxuiCount, setUxuiCount] = useState(undefined)
  const [webMobileCount, setWebMobileCount] = useState(undefined)
  const [dataScienceCount, setDataScienceCount] = useState(undefined)

  const [aimlCount, setAimlCount] = useState(undefined)
  const [developmentCount, setDevelopmentCount] = useState(undefined)

  const setTagCount = (res, slug, setter) => {
    const tagObj = res.tags.find(tag => {return tag.slug === slug})
    if (tagObj && tagObj.count && tagObj.count.posts) {
      setter(tagObj.count.posts)
    }
  }

  useEffect(() => {
    const key = '43e5445edb2c9d9e4f8c8ce161'
    fetch('https://blog.lab201.com/ghost/api/content/tags/?key=' + key + '&include=count.posts')
    .then(response => response.json())
    .then(res => {
      setTagCount(res, 'product-design', setProductDesignCount)
      setTagCount(res, 'branding', setBrandingCount)

      //setTagCount(res, 'web-mobile', setWebMobileCount)
      setTagCount(res, 'ux-ui', setWebMobileCount)
      setTagCount(res, 'ux-ui', setUxuiCount)
      setTagCount(res, 'research', setDataScienceCount)

      //setTagCount(res, 'ai-ml', setAimlCount)
      setTagCount(res, 'research', setAimlCount)
      setTagCount(res, 'development', setDevelopmentCount)
    })
  }, [])

  return (
    <div id="page-2" className="page" data-section-name="work">
      <svg width="1" height="1">
        <defs>
          <filter id="blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="0 10" />
          </filter>
        </defs>
      </svg>

      <div className="what-we-do">
        <div id="capabilities">
          <span id="work-1-kr">우리는</span>
          <div className="word-container">
            <span id="active-word-kr"> everything</span>
            <span id="underline" className="underline-kr"></span>
            <span id="work-2">을</span>
          </div>
          <div id="line-2-kr">시도하고 있어요</div>
        </div>

        <div className="about-tile-container">
          <div className="tile-group tile-group--1" groupid="1">
            <CapabilityTile name="프로덕트 디자인" slug="product-design" count={productDesignCount} />
            <CapabilityTile name="브랜드 아이덴티티" slug="branding" count={brandingCount} />
          </div>

          <div className="tile-group tile-group--2" groupid="2">
            <CapabilityTile name="웹/모바일" slug="ux-ui" count={webMobileCount} />
            <CapabilityTile name="UX/UI" slug="ux-ui" count={uxuiCount} />
            <CapabilityTile name="Data Science" slug="research" count={dataScienceCount} />
         </div>

          <div className="tile-group tile-group--1" groupid="3">
            <CapabilityTile name="AI/ML" slug="research" count={aimlCount} />
            <CapabilityTile name=">소프트웨어" slug="development" count={developmentCount} />
            <div id="and-more" className="about-tile about-tile-rev p1" style={{marginRight: 0}}>그리고 그 이상</div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CapabilityTile ({name, count, slug}) {
  const navigate = useNavigate()
  const badge = count ? (<div className="tile-badge">{count}</div>) : ''
  const goToWork = (tag) => {
    navigate("/work", { state: { active: tag } });
  }
  return (
    <div className="about-tile" onClick={() => goToWork(slug)}>
      { name }
      { badge }
    </div>
  )
}

function ContactKR () {
  return (
    <div id="page-3" className="page" data-section-name="contact">
      <div className="cool-shit">
        <span id="contact">
          <span className="contact-regular">함께해요. <br /></span>
          <span className="contact-regular"><b>더 혁신적</b>이고 <br /></span>
          <span className="contact-regular"><b>더 사람</b>답게.</span>
        </span>
        <div id="say-hello">저희와 함께하고 싶은 프로젝트가 있거나<br />질문이 있으시다면 언제든 연락주세요.</div>
        <div className="contact-email"><a id="contact-email" href="mailto:hello@lab201.com">hello@lab201.com</a></div>
        <img className="static-scribble" src={scribble} />

        <svg id="scribble-svg" className="text" width="100%" aria-hidden="true">
          <path id="scribble" className="" style={{transform: 'translateY(-10px) scale(1.375)'}} fill="none" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.7 10.2c76.5 4.4 153.6-9.7 229.8-4.1 5.4.4 100.4 2.1 11.7 1.6-67.3 1.7-134.5 2.5-201.2 11.5l87.7-.9c35.2-.4 100.8-.7 104.9 4.6"></path>
        </svg>
      </div>
    </div>
  )
}

export { HomeKR };
