export function setCursorMode (targetClass, cursorClass) {
    const $cursor = document.querySelector('.cursor')
    const $targets = document.querySelectorAll(targetClass)

    $targets?.forEach((target) => {
      target.addEventListener('mouseover', () => {
        $cursor.classList.add(cursorClass)
      })
    })

    $targets?.forEach((target) => {
      target.addEventListener('mouseleave', () => {
        $cursor.classList.remove(cursorClass)
      })
    })
  }


