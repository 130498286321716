import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import GhostContentAPI from '@tryghost/content-api'

import { Navbar, Footer} from './lab201'

import {setCursorMode} from './utils'
import './styles/work.css'

const api = new GhostContentAPI({
    url: 'https://blog.lab201.com',
    key: '43e5445edb2c9d9e4f8c8ce161',
    version: "v5.0"
})

function Tile({work, isHidden, isAnimating}) {
  const [imgLoaded, setImgLoaded] = useState(false)
  const tClass = 'work__tile--transition-' + Math.floor(Math.random() * 4)
  const getClass = (isHidden, isAnimating, imgLoaded) => {
    var classString = tClass
    if (!isAnimating) classString += ' work__tile-reset'
    if (!isHidden && imgLoaded) classString += ' work__tile-cover'
    return classString
  }
  return (
    <a className="work__tile-container" href={"/content/" + work.slug} key={work.id}>
      <div className={"work__tile"}>
        <div className="work__tile-background">
          <div className="work__tile-title">
            {work.title}
          </div>
        </div>
        <img className={getClass(isHidden, isAnimating, imgLoaded)} src={work.feature_image} onLoad={()=>setImgLoaded(true)}/>
      </div>
    </a>
  )
}

function Tiles ({works, isHidden, setIsHidden, isAnimating, setIsAnimating}) {
  useEffect(() => {
    setCursorMode('.work__content', 'cursor--noblend')
    setCursorMode('.work__tile', 'cursor--noblend-hover')
  },  [works])
  return (
    <div className="work__content">
      {works.map((work) => {
        const tClass = 'work__tile--transition-' + Math.floor(Math.random() * 4)
        return (
          <Tile work={work} isHidden={isHidden} isAnimating={isAnimating} key={work.id} />
        )
      })}
    </div>
  )
}

export function Work () {
  var mouseY = 0
  var lastScrollY = 0

  const { state } = useLocation()
  const [tags, setTags] = useState([])
  const [works, setWorks] = useState([])
  const [isHidden, setIsHidden] = useState(true)
  const [isAnimating, setIsAnimating] = useState(true)

  const [activeTag, setActiveTag] = useState(null)

  const updateTiles = (tag) => {
    setActiveTag(tag)
    const options = (tag ? {include: 'tags', filter: ('tags:' + tag)} : {include: 'tags'})

    setIsHidden(true)
    setTimeout(() => {
      setIsAnimating(false)
    }, 100)
    api.posts.browse(options)
    .then((posts) => {
      setTimeout(function(){
        setWorks(posts)
        setTimeout(() => {
          setIsAnimating(true)
          setIsHidden(false)
        }, 100);
      }, 100);
   })
  }

  const onMouseMove = (e) => {
    const $cursor = document.querySelector('.cursor')
    lastScrollY = document.querySelector('html').scrollTop
    var x = e.pageX;
    var y = mouseY = e.pageY;
    $cursor.style.left = x + 'px';
    $cursor.style.top = y + 'px';
  }

  const onScroll = (e) => {
    const $cursor = document.querySelector('.cursor')
    const scrollY = document.querySelector('html').scrollTop
    const offset = lastScrollY - scrollY
    $cursor.style.top = (mouseY - offset) + 'px'
  }

  useEffect(() => {
    window.history.replaceState({}, document.title)
    document.body.addEventListener('mousemove', onMouseMove);
    window.addEventListener('scroll', onScroll);

    setCursorMode('.navbar__link', 'lang-hover')

/*    api.posts.browse({limit: 10, include: 'tags,authors'})
      .then((posts) => {
        setWorks(posts)
        setCursor('work__tile', 'lang-hover')
        //setIsAnimating(true)
        setIsHidden(false)
    })
    .catch((err) => {console.error(err)})
    */
/*
    setIsHidden(true)
    setTimeout(() => {
      setIsAnimating(false)
    }, 300)
    api.posts.browse({limit: 10, include: 'tags,authors'})
    .then((posts) => {
      setTimeout(function(){
        //setIsAnimating(false)
        setWorks(posts)
        setTimeout(() => {
          setIsAnimating(true)
          setIsHidden(false)
        }, 300);
      }, 300);
   })
 */

    api.tags.browse({include: 'count.posts'})
      .then((tags) => {
        setTags(tags)
        setCursorMode('.work__tag', 'lang-hover')
        updateTiles(state?.active)
      })
      .catch((err) => {console.error(err)})
    }, [])

  return (
    <div className="work__container">
      <Navbar active={'work'} hasLogo={true}/>

      <div className="work__tags">
        <div className={"work__tag work__tag--all" + (!activeTag ? " work__tag--active" : "")}
            onClick={() => updateTiles(null)}>
          All
        </div>
        {tags.map((tag) => {
          return (
            <div className={"work__tag" + (activeTag === tag.slug ? ' work__tag--active' : '')} key={tag.id} onClick={() => updateTiles(tag.slug)}>
              <div className="work__tag-name">{tag.name}</div>
              <div className="work__tag-count"> ({tag.count.posts})</div>
            </div>
          )
        })}
      </div>

      <Tiles works={works} setIsHidden={setIsHidden} isHidden={isHidden} isAnimating={isAnimating} setIsAnimating={setIsAnimating}/>

      <Footer lang={'en'} />
    </div>
  )
}
